import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import { FaHome, FaUser } from "react-icons/fa";
import { Amplify, Auth, Hub } from 'aws-amplify';
import { Authenticator, Button, Text, Badge } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

export default function Receipts() {

  const restaurantData = {
    "tuttobenegrazie": {
      restaurant_display_name: "Tutto Bene, Grazie Bistrò",
      restaurant_logo_url: "https://scontrini.gotaac.it/merchant-logos/tbg.jpg"
    },
    "martas": {
      restaurant_display_name: "Martas Nature Bar",
      restaurant_logo_url: "https://scontrini.gotaac.it/merchant-logos/Martas Nature Bar.png"
    },
    "lomdopolavoro": {
      restaurant_display_name: "Lom Dopolavoro",
      restaurant_logo_url: "https://menu.gotaac.it/lomdopolavoro.jpg"
    }
  };

  const [content, setContent] = useState([]);
  const [expandedCard, setExpandedCard] = useState(null);

  useEffect(() => {
    // Configure the AWS SDK
    AWS.config.update({
      region: 'eu-west-1',
      accessKeyId: 'AKIAYFDYAK75ALG6K3RK',
      secretAccessKey: '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'
    });

    // Handle user authentication
    let updateUser = async authState => {
      try {
        let user = await Auth.currentAuthenticatedUser()
        loadReceipts(user);
      } catch {
        console.log("User not authenticated.")
      }
    }
    // Listen for login/signup events
    Hub.listen('auth', updateUser)
    // Check manually the first time because we won't get a Hub event
    updateUser()
    // Cleanup
    return () => Hub.remove('auth', updateUser)
  }, []);

  function loadReceipts(user) {
    // Create a new DynamoDB service object
    const dynamoDb = new AWS.DynamoDB();
    // Define the parameters for the query operation
    const params = {
      TableName: 'taac-guests-webapp-orders',
      KeyConditionExpression: "#username = :username",
      ExpressionAttributeNames: {
        "#username": "username",
      },
      ExpressionAttributeValues: {
        ":username": {
          N: user.username,
        },
      },
    };
    // Call the query method to retrieve all items in the table for the user
    dynamoDb.query(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        setContent(data.Items);
      }
    });
  }

  function openDigitalReceipt(url) {
    setTimeout(() => {
      window.open(url, '_blank');
    })
  }

  function formatDate(dateString) {
    let date = new Date(dateString.slice(0, 4), dateString.slice(4, 6) - 1, dateString.slice(6, 8), dateString.slice(8, 10), dateString.slice(10, 12), dateString.slice(12, 14));
    return date.toLocaleString("it-IT", { day: "2-digit", month: "2-digit", hour: "2-digit", minute: "2-digit"}).replace(/\//g, "/").replace(/\s/, " - ").replace(/\,/, "");
  }  

  const components = {
    Header() {
      return (
        <div className='auth-header'>
          <img
            className='auth-logo'
            alt="taac logo"
            src="https://scontrini.gotaac.it/taac-logo-header.png"
          />
        </div>
      );
    },
    SignUp: {
      Header() {
        return (
          <Text
            className='auth-signup-header'
            fontWeight={"bold"}
            fontSize={"1.4rem"}
          >
            Scegli un nome utente da 4 cifre
          </Text>
        );
      },
    }
  }

  return (
    <Authenticator variation='modal' initialState="signUp" components={components}>
      <main>
        <div className="navbar">
          <Link to="/"><FaHome className='navbar-home-icon' /></Link>
          <img src="https://scontrini.gotaac.it/taac-logo-header.png" className="logo" alt="taac logo" />
          <Link to="/profile"><FaUser className='navbar-secondary-icon' /></Link>
        </div>

        <div className='pageContent'>
          {content
            .sort((a, b) => b.date.S.localeCompare(a.date.S))
            .map((item, index) => (
              <div className={`card ${expandedCard === index ? 'expanded' : ''}`} key={index}>
                <div className='flex' onClick={() => setExpandedCard(expandedCard === index ? null : index)}>
                  <img className='venue-logo' src={restaurantData[item.restaurant_name.S].restaurant_logo_url} alt="restaurant logo" />
                  <div className='venue-info'>
                    <div className='flex-row-space-between'>
                      <Text>{formatDate(item.date.S)}</Text>
                      <Badge>
                        <CurrencyFormat value={item.order_total.N} displayType={'text'} thousandSeparator={true} prefix={'€'} decimalScale={2} fixedDecimalScale={true} />
                      </Badge>
                    </div>
                    <Text fontWeight={"bold"} fontSize={"1.2rem"}>{restaurantData[item.restaurant_name.S].restaurant_display_name}</Text>
                  </div>
                </div>
                {expandedCard === index && (
                  <>
                    <Button
                      className="single-gap"
                      variation={'secondary'}
                      onClick={() => openDigitalReceipt(item.fiscal_printer_link.S)}
                      isFullWidth={true}>
                      scarica scontrino digitale
                    </Button>
                  </>
                )}
              </div>
            ))}
        </div>
      </main>
    </Authenticator>
  );
}