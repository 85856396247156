import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Button, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

export default function DocumentScreen() {

  const params = useParams();

  const receiptUUID = params.receiptUUID;
  // eslint-disable-next-line
  const receiptLink = "https://www.scontrinosmart.it/er/" + receiptUUID + ".pdf" + "#zoom=165%" + "&toolbar=0" + "&navpanes=0"
  const receiptLinkDownload = "https://www.scontrinosmart.it/er/" + receiptUUID + ".pdf"

  const [expandedCardPreview, setExpandedCardPreview] = useState(null);

  function openUrl(url) {
    setTimeout(() => {
      window.open(url, '_blank');
    })
  }

  return (
    <main>
      <div className="navbar">
        <img src="https://scontrini.gotaac.it/taac-logo-header.png" className="logo" alt="taac logo" />
      </div>

      <div className='pageContent'>
        <div className="card text-align-center">
          <Text>registrati per conservare tutti i tuoi scontrini e</Text>
          <Text> e scegliere il tuo codice taac personale</Text>
          <Link to="/">
            <Button
              className="double-gap button-black"
              isFullWidth={true}
              variation="primary">
              registrati
            </Button>
          </Link>
        </div>
        <Text className='text-align-center triple-gap-top-bottom'>oppure</Text>
        <div className='card-profile'>
          <div className="text-align-center">
            <Text>visualizza e scarica lo scontrino digitale</Text>
            <Text>ha la stessa validità legale del cartaceo</Text>
            <Button
              className="double-gap"
              isFullWidth={true}
              onClick={() => openUrl(receiptLinkDownload)}
              variation="secondary">
              scarica scontrino digitale
            </Button>
          </div>
          <div>
          {!expandedCardPreview && 
            <Button
              className="double-gap button-black"
              isFullWidth={true}
              onClick={() => setExpandedCardPreview(!expandedCardPreview)}
              variation="secondary">
              mostra anteprima
            </Button>
          }
          {expandedCardPreview && 
            <Button
              className="double-gap button-black"
              isFullWidth={true}
              onClick={() => setExpandedCardPreview(!expandedCardPreview)}
              variation="secondary">
              nascondi anteprima
            </Button>
          }
          </div>
          {expandedCardPreview && (
            <>
              <div className='single-gap'>
                <iframe title="previewFrame" src={receiptLink} className="pdf-frame"></iframe>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
}