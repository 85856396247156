import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import QRCode from "react-qr-code";
import { FaAngleDown, FaAngleUp, FaHome, FaReceipt } from "react-icons/fa";
import { Amplify, Auth } from 'aws-amplify';
import { Authenticator, Button, TextField, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

export default function Profile() {

  // Allow user to permanently delete his account
  async function deleteUser() {
    const confirm = window.confirm("Vuoi davvero cancellare il tuo account? Non potrai recuperarlo in futuro.");
    if (confirm) {
      try {
        const result = await Auth.deleteUser();
        console.log(result);
      } catch (error) {
        console.log('Error deleting user', error);
      }
    }
  }

  // Allow user to change his password
  const [expandedCardPassword, setExpandedCardPassword] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  async function changePassword() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        console.log(data)
        alert("Password aggiornata correttamente!");
      })
      .catch((err) => {
        console.log(err)
        alert("Si è verificato un errore: controllare di aver inserito la password corretta");
      });
  }

  // Allow user to add or change other optional attributes
  const [expandedCardUserInfo, setExpandedCardUserInfo] = useState(null);
  const [newGivenName, setNewGivenName] = useState('');
  const [newFamilyName, setNewFamilyName] = useState('');
  async function updateUserAttributes() {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.updateUserAttributes(user, {
      given_name: newGivenName,
      family_name: newFamilyName
    });
    alert("Dati personali aggiornati correttamente!");
    console.log(result);
  }

  const components = {
    Header() {
      return (
        <div className='auth-header'>
          <img
            className='auth-logo'
            alt="taac logo"
            src="https://scontrini.gotaac.it/taac-logo-header.png"
          />
        </div>
      );
    },
    SignUp: {
      Header() {
        return (
          <Text
            className='auth-signup-header'
            fontWeight={"bold"}
            fontSize={"1.4rem"}
          >
            Scegli un nome utente da 4 cifre
          </Text>
        );
      },
    }
  }

  return (
    <Authenticator variation='modal' initialState="signUp" components={components}>
      {({ signOut, user }) => (
        <main>
          <div className="navbar">
            <Link to="/"><FaHome className='navbar-home-icon' /></Link>
            <img src="https://scontrini.gotaac.it/taac-logo-header.png" className="logo" alt="taac logo" />
            <Link to="/receipts"><FaReceipt className='navbar-secondary-icon' /></Link>
          </div>

          <div className='pageContent'>
            <div className='card'>
              <div className='flex'>
                <div className='profile-QR-code'>
                  <QRCode
                    className='profile-QR-code-img'
                    size={256}
                    value={user.username}
                  />
                </div>
                <div>
                  <Text
                    fontWeight={'extrabold'}
                    fontSize={'2.5rem'}
                    color={"#ee492f"}
                    className='username-text'>
                    {user.username}
                  </Text>
                  <Text>
                    {user.attributes.email}
                  </Text>
                </div>
              </div>
            </div>

            <div className='card-profile'>
              <div className='card-profile-collapsable-header' onClick={() => setExpandedCardPassword(!expandedCardPassword)}>
                <Text>
                  modifica password
                </Text>
                {!expandedCardPassword && <span><FaAngleDown /></span>}
                {expandedCardPassword && <span><FaAngleUp /></span>}
              </div>
              {expandedCardPassword && (
                <>
                  <div className='single-gap'>
                    <TextField
                      name="oldPassword"
                      placeholder="Password corrente"
                      onChange={e => setOldPassword(e.target.value)}
                    />
                    <TextField
                      name="newPassword"
                      placeholder="Nuova password"
                      onChange={e => setNewPassword(e.target.value)}
                    />
                    <Button
                      className="single-gap button-black"
                      onClick={changePassword}
                      isFullWidth={true}
                      variation="primary">
                      conferma
                    </Button>
                  </div>
                </>
              )}
            </div>

            <div className='card-profile'>
              <div className='card-profile-collapsable-header' onClick={() => setExpandedCardUserInfo(!expandedCardUserInfo)}>
                <Text>
                  aggiorna dati personali
                </Text>
                {!expandedCardUserInfo && <span><FaAngleDown /></span>}
                {expandedCardUserInfo && <span><FaAngleUp /></span>}
              </div>
              {expandedCardUserInfo && (
                <>
                  <div className='single-gap'>
                    <TextField
                      name="given_name"
                      placeholder={user.attributes.given_name ? user.attributes.given_name : "Nome"}
                      onChange={e => setNewGivenName(e.target.value)}
                    />
                    <TextField
                      name="family_name"
                      placeholder={user.attributes.family_name ? user.attributes.family_name : "Cognome"}
                      onChange={e => setNewFamilyName(e.target.value)}
                    />
                    <Button className="single-gap button-black" onClick={updateUserAttributes} variation="primary" isFullWidth={true}>conferma</Button>
                  </div>
                </>
              )}
            </div>
            <Button onClick={signOut} isFullWidth={true} variation="destructive">esci</Button>
            <Button onClick={deleteUser} isFullWidth={true} variation="warning" className='single-gap'>elimina account</Button>
          </div>
        </main>
      )}
    </Authenticator>
  );
}