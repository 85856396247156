import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

import { Link, useParams } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Button, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

export default function SplitAndPayDocumentScreen() {

  const params = useParams();

  const orderUUID = params.orderUUID;

  const [content, setContent] = useState([]);

  const restaurantData = {
    "tuttobenegrazie": {
      restaurant_display_name: "Tutto Bene, Grazie Bistrò",
      restaurant_logo_url: "https://scontrini.gotaac.it/merchant-logos/tbg.jpg"
    },
    "martas": {
      restaurant_display_name: "Martas Nature Bar",
      restaurant_logo_url: "https://scontrini.gotaac.it/merchant-logos/Martas Nature Bar.png"
    },
    "lomdopolavoro": {
      restaurant_display_name: "Lom Dopolavoro",
      restaurant_logo_url: "https://menu.gotaac.it/lomdopolavoro.jpg"
    }
  };

  useEffect(() => {
    // Configure the AWS SDK
    AWS.config.update({
      region: 'eu-west-1',
      accessKeyId: 'AKIAYFDYAK75ALG6K3RK',
      secretAccessKey: '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'
    });
    loadReceipts(orderUUID)
  }, []);

  function loadReceipts(uuid) {
    // Create a new DynamoDB service object
    const dynamoDb = new AWS.DynamoDB();
    // Define the parameters for the query operation
    const params = {
      TableName: 'taac-guests-webapp-orders',
      KeyConditionExpression: "#username = :username and #uuid = :uuid",
      ExpressionAttributeNames: {
        "#username": "username",
        "#uuid": "uuid",
      },
      ExpressionAttributeValues: {
        ":username": {
          N: String(0),
        },
        ":uuid": {
          S: uuid,
        },
      },
    };
    // Call the query method to retrieve the specific receipt
    dynamoDb.query(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        if (data.Count == 1) {
          const receiptLinkBase = data.Items[0].fiscal_printer_link.S
          setContent(receiptLinkBase);
        }
        else {
          setContent(undefined);
        }
      }
    });
  }

  // eslint-disable-next-line
  const receiptLink = content + "#zoom=165%" + "&toolbar=0" + "&navpanes=0"

  const [expandedCardPreview, setExpandedCardPreview] = useState(null);

  function openUrl(url) {
    setTimeout(() => {
      window.open(url, '_blank');
    })
  }

  function refreshPage () {
    window.location.reload(false);
  }

  return (
    <main>
      <div className="navbar">
        <img src="https://scontrini.gotaac.it/taac-logo-header.png" className="logo" alt="taac logo" />
      </div>

      <div className='pageContent'>
        {content ?
        <div className='card-profile'>
          <div className="text-align-center">
            <Text>visualizza e scarica lo scontrino digitale</Text>
            <Text>ha la stessa validità legale del cartaceo</Text>
            <Button
              className="double-gap"
              isFullWidth={true}
              onClick={() => openUrl(content)}
              variation="secondary">
              scarica scontrino digitale
            </Button>
          </div>
          <div>
          {!expandedCardPreview && 
            <Button
              className="double-gap button-black"
              isFullWidth={true}
              onClick={() => setExpandedCardPreview(!expandedCardPreview)}
              variation="secondary">
              mostra anteprima
            </Button>
          }
          {expandedCardPreview && 
            <Button
              className="double-gap button-black"
              isFullWidth={true}
              onClick={() => setExpandedCardPreview(!expandedCardPreview)}
              variation="secondary">
              nascondi anteprima
            </Button>
          }
          </div>
          {expandedCardPreview && (
            <>
              <div className='single-gap'>
                <iframe title="previewFrame" src={receiptLink} className="pdf-frame"></iframe>
              </div>
            </>
          )}
        </div>
        :
        <div className='card-profile'>
          <div className="text-align-center">
            <Text>scontrino non ancora disponibile</Text>
            <Text>attendere qualche istante per ricaricare la pagina</Text>
            <Button
              className="double-gap"
              isFullWidth={true}
              onClick={() => refreshPage()}
              variation="secondary">
              ricarica
            </Button>
          </div>
        </div>
        }
        <Text className='text-align-center triple-gap-top-bottom'>oppure</Text>
        <div className="card text-align-center">
          <Text>registrati per conservare tutti i tuoi scontrini e</Text>
          <Text> e scegliere il tuo codice taac personale</Text>
          <Link to="/">
            <Button
              className="double-gap button-black"
              isFullWidth={true}
              variation="primary">
              registrati
            </Button>
          </Link>
        </div>
      </div>
    </main>
  );
}