import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';

import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { Amplify } from 'aws-amplify';
import { Text, Button, Badge } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

export default function LomDopolavoro() {


  const restaurantData = {
    "tuttobenegrazie": {
      restaurant_display_name: "Tutto Bene, Grazie Bistrò",
      restaurant_logo_url: "https://scontrini.gotaac.it/merchant-logos/tbg.jpg"
    },
    "martas": {
      restaurant_display_name: "Martas Nature Bar",
      restaurant_logo_url: "https://scontrini.gotaac.it/merchant-logos/Martas Nature Bar.png"
    },
    "lomdopolavoro": {
      restaurant_display_name: "Lom Dopolavoro",
      restaurant_logo_url: "https://menu.gotaac.it/lomdopolavoro.jpg"
    }
  };

  const [content, setContent] = useState([]);
  const [expandedCard, setExpandedCard] = useState(null);
  const [temporaryID, setTemporaryID] = useState([]);
  const [firstTry, setFirstTry] = useState(true);

  useEffect(() => {
    // Configure the AWS SDK
    AWS.config.update({
      region: 'eu-west-1',
      accessKeyId: 'AKIAYFDYAK75ALG6K3RK',
      secretAccessKey: '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'
    });

    function randomInts() {
      const set = new Set()
      while (set.size < 5) {
        set.add(Math.floor(Math.random() * 9) + 1)
      }
      setTemporaryID(Array.from(set))
    }
    randomInts();
  }, []);

  function openDigitalReceipt(url) {
    setTimeout(() => {
      window.open(url, 'download=false');
    })
  }

  function formatDate(dateString) {
    let date = new Date(dateString.slice(0, 4), dateString.slice(4, 6) - 1, dateString.slice(6, 8), dateString.slice(8, 10), dateString.slice(10, 12), dateString.slice(12, 14));
    return date.toLocaleString("it-IT", { day: "2-digit", month: "2-digit", hour: "2-digit", minute: "2-digit"}).replace(/\//g, "/").replace(/\s/, " - ").replace(/\,/, "");
  }  
  
  function loadReceipts() {
    setFirstTry(false);
    // Create a new DynamoDB service object
    const dynamoDb = new AWS.DynamoDB();
    // Define the parameters for the query operation
    const params = {
      TableName: 'taac-guests-webapp-orders',
      KeyConditionExpression: "#username = :username",
      ExpressionAttributeNames: {
        "#username": "username",
      },
      ExpressionAttributeValues: {
        ":username": {
          N: String(parseInt(temporaryID.join(''))),
        },
      },
    };
    // Call the query method to retrieve all items in the table for the user
    dynamoDb.query(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        console.log(data.Items);
        setContent(data.Items);
      }
    });
  }

  return (
    <main>
      <div className="navbar">
        <img src="https://scontrini.gotaac.it/taac-logo-header.png" className="logo" alt="taac logo" />
      </div>

      <div className='pageContent'>
        <div className="card text-align-center">
          <Text>registrati per conservare tutti i tuoi scontrini e</Text>
          <Text> e scegliere il tuo codice taac personale</Text>
          <Link to="/">
            <Button
              className="double-gap button-black"
              isFullWidth={true}
              variation="primary">
              registrati
            </Button>
          </Link>
        </div>
        <Text className='text-align-center triple-gap-top-bottom'>oppure</Text>
        <div className="card text-align-center">
          <Text>comunica il codice usa e getta in cassa</Text>
          <Text
            fontWeight={'extrabold'}
            fontSize={'2.5rem'}
            color={"#ee492f"}>
            {temporaryID}
          </Text>
          <Text>clicca sul pulsante sotto una volta terminato il pagamento</Text>
          <Button
            className="double-gap button-black"
            isFullWidth={true}
            onClick={() => loadReceipts()}
            variation="primary">
            carica scontrino digitale
          </Button>
          {content.length === 0 && !firstTry ? <Text className='double-gap text-align-center'>nessuno scontrino da visualizzare - riprovare tra qualche istante</Text> : null}
        </div>
        <div>
        <div>
          {content
            .sort((a, b) => b.date.S.localeCompare(a.date.S))
            .slice(0, 2).map((item, index) => (
              <div className={`card ${expandedCard === index ? 'expanded' : ''}`} key={index}>
                <div className='flex' onClick={() => setExpandedCard(expandedCard === index ? null : index)}>
                  <img className='venue-logo-small' src={restaurantData[item.restaurant_name.S].restaurant_logo_url} alt="restaurant logo" />
                  <div className='venue-info'>
                    <div className='flex-row-space-between'>
                      <Text fontSize={"0.9rem"}>{formatDate(item.date.S)}</Text>
                      <Badge
                        size='small'>
                        <CurrencyFormat value={item.order_total.N} displayType={'text'} thousandSeparator={true} prefix={'€'} decimalScale={2} fixedDecimalScale={true} />
                      </Badge>
                    </div>
                    <Text fontWeight={"bold"} fontSize={"1.08rem"}>{restaurantData[item.restaurant_name.S].restaurant_display_name}</Text>
                  </div>
                </div>
                {expandedCard === index && (
                  <>
                    <Button
                      className="single-gap"
                      variation={'secondary'}
                      onClick={() => openDigitalReceipt(item.fiscal_printer_link.S)}
                      isFullWidth={true}>
                      scarica scontrino digitale
                    </Button>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
      </div>
    </main>
  );
}