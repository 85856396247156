import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.css';

import App from './App';
import Receipts from './Receipts';
import Profile from './Profile';
import GuestsTuttoBeneGrazie from './GuestsTuttoBeneGrazie';
import LomDopolavoro from './LomDopolavoro';
import DocumentScreen from './DocumentScreen';
import SplitAndPayDocumentScreen from './SplitAndPayDocumentScreen';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="receipts" element={<Receipts />} />
      <Route path="profile" element={<Profile />} />
      <Route path="tuttobenegrazie" element={<GuestsTuttoBeneGrazie />} />
      <Route path="lomdopolavoro" element={<LomDopolavoro />} />
      <Route path="/doc/:receiptUUID" element={<DocumentScreen />} />
      <Route path="/split-and-pay/:orderUUID" element={<SplitAndPayDocumentScreen />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
