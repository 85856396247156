import React from 'react';
import { Link } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Button, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

export default function GuestsTuttoBeneGrazie() {

  return (
    <main>
      <div className="navbar">
        <img src="https://scontrini.gotaac.it/taac-logo-header.png" className="logo" alt="taac logo" />
      </div>

      <div className='pageContent'>
        <div className="card text-align-center">
          <Text>registrati per conservare tutti i tuoi scontrini e</Text>
          <Text> e scegliere il tuo codice taac personale</Text>
          <Link to="/">
            <Button
              className="double-gap button-black"
              isFullWidth={true}
              variation="primary">
              registrati
            </Button>
          </Link>
        </div>
        <div className="card text-align-center">
          <Text>lo scontrino digitale è firmato digitalmente e ha la stessa validità legale del cartaceo</Text>
          <Text className='single-gap'>puoi usarlo per le tue note spese e chiedere rimborsi</Text>
          <Text className='single-gap'>registrandoti avrai lo storico dei tuoi ordini sempre a portata di mano</Text>
        </div>
      </div>
    </main>
  );
}